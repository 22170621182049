<template>
  <div>
    <CompanyInfo
      :images="images"
      :companyBrandColour="companyBrandColour"
      :companyLogo="companyLogo"
    >
      <template #companyName>Vifor Pharma</template>
      <template #date>2017 - Ongoing</template>
      <template #content>
        <ul class="dashed">
          <li>
            Helped produce an interactive entertaining and educational activity with our
            moderated keypad voting system for a series of European Heart Failure
            congresses.
          </li>
          <li>
            Our task was to reaffirm key messaging to healthcare professionals around iron
            deficiency in the blood. Healthcare professionals were challenged by a series
            of questions and discovered any gaps in their knowledge. Delegate’s answers
            were saved and this important information was sent back to Vifor Pharma for
            later analysis.
          </li>
          <li>
            Host moderation was provided to support the ‘Meet the Expert' sessions on the
            booth. This encouraged delegates to participate in the talks and helped to
            support the guest speakers with setting up an introduction and enabling a
            smooth presentation.
          </li>
        </ul>
      </template>
    </CompanyInfo>
  </div>
</template>

<script>
import CompanyInfo from "@/components/Portfolio/CompanyInfo.vue";
export default {
  components: { CompanyInfo },
  data() {
    return {
      companyBrandColour: "#1B488F",
      companyLogo: require("@/assets/images/portfolio/vifor-pharma-logo.svg"),
      images: [
        {
          id: 1,
          responsiveImages: require("@/assets/images/portfolio/vifor-pharma/1.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1",
          pictureDetails: "HEART FAILURE CONGRESS (2019) ATHENS",
        },
        {
          id: 0,
          responsiveImages: require("@/assets/images/portfolio/vifor-pharma/2.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1",
          pictureDetails: "HEART FAILURE CONGRESS (2018) VIENNA",
        },
      ],
    };
  },
};
</script>
